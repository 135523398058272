import React, { useEffect, useState } from "react";
import Heading from "../../../Components/home/HeadingProducts";
import axios from "axios"

export default function BarkodSkaner() {

  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch(
        "https://www.inteko.az/inteko/dashboard.php/",
        {
          credentials: 'omit'
        }
      );
      
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      
      const data = await response.json();
      setItems(data.filter(item => item.category === "Barkod Oxuyucu"));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };
  


  return (
    <>
       <Heading heading="Barkod skanerlər" title="Əsas" title2='Məhsullar' subtitle="Barkod skanerlər" />
      <div className='container w-[80%]'>
      <div className='content grid3'>
        {list.map((val, index) => {
          const { image, category, name } = val;
          return (
            <div className='box shadow' key={index}>
              <div className='img'>
                <img src={`data:image/jpeg;base64,${image}`} alt={name} />
              </div>
              <div className='text'>
                <div className='category flex'>
                  <span>{category}</span>
                  <i className='fa fa-heart'></i>
                </div>
                <h4>{name}</h4>
                <p>
                  <i className='fa fa-map-marker me-2'></i> Bakı, Azərbaycan
                </p>
                <a href="/product-detail"><button className='details-button'>Details</button></a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
    </>
  );
}
 