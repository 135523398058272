import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Services from "../../Components/Services/Services";

export default function About() {
  return (
    <>
      <Heading heading="Xidmətlərimiz" title="Əsas" subtitle="Xidmətlərimiz" />
      <Services/>
    </>
  );
}
