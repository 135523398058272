import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { logo, mpost } from "../../assets/index";


const Header = () => {
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);

  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };

  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };

  return (
    <>
      <nav className="bg-white border-b border-gray-200 py-2 ">
        <div className="w-[92%] mx-auto">
          <div className="container mx-auto flex items-center justify-between py-3">
            <div className="flex items-center">
              <a href='/'> <img src={logo} alt="Logo" className="h-16" /></a>
            </div> 
            <div className="flex space-x-6 text-md"> 
              <a href="/" className="text-[#086170] font-bold">Əsas səhifə</a>
              <a href="/about" className="text-[#086170] font-bold">Haqqımızda</a>
              <div 
                className="relative flex items-center"
                onMouseEnter={openServicesMenu}
                onMouseLeave={closeServicesMenu}
              >
                <button 
                  className="text-[#086170] font-bold focus:outline-none"
                  onClick={(e) => e.preventDefault()}
                >
                  Xidmətlər
                </button>
                <button
                  onClick={() => setIsServicesOpen(!isServicesOpen)}
                  className="ml-2 focus:outline-none"
                >
                 <i class="fa-solid fa-caret-down text-[#086170]"></i> 
                </button>
                {isServicesOpen && (
                  <div 
                    className="absolute top-full left-0 mt-2 bg-white border border-gray-200 shadow-lg z-20 w-48"
                    onMouseEnter={openServicesMenu}
                    onMouseLeave={closeServicesMenu}
                  >
                     <a href="/services" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Bütün xidmətlər</a>
                    <a href="/muhasibatliq" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Mühasibatlıq</a>
                    <a href="/maliyye" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Maliyyə</a>
                    <a href="/vergi-ucotu" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Vergi uçotu</a>
                    <a href="/insan-resurslari" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">İnsan resursları</a>
                    <a href="/proqramlasdirma" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Proqramlaşdırma</a>
                    <a href="/e-kassa" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">E-kassa</a>
                    <a href="/sebeke-idareetmesi" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Şəbəkə idarəetməsi</a>
                  </div>
                )}
              </div>
              <a href="/partners" className="text-[#086170] font-bold">Müştərilərimiz</a>
              <div 
                className="relative flex items-center"
                onMouseEnter={openProductsMenu}
                onMouseLeave={closeProductsMenu}
              >
                <button 
                  className="text-[#086170] font-bold focus:outline-none"
                  onClick={(e) => e.preventDefault()}
                >
                  Məhsullar
                </button>
                <button
                  onClick={() => setIsProductsOpen(!isProductsOpen)}
                  className="ml-2 focus:outline-none"
                >
                  <i class="fa-solid fa-caret-down text-[#086170]"></i> 
                </button>
                {isProductsOpen && (
                  <div 
                    className="absolute top-full left-0 mt-2 bg-white border border-gray-200 shadow-lg z-50 w-48"
                    onMouseEnter={openProductsMenu}
                    onMouseLeave={closeProductsMenu}
                  >
                    <a href="/products" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Bütün məhsullar</a>
                    <a href="/pul-yesikleri" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Pul yeşikləri</a>
                    <a href="/all-in-one" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">All-In-One</a>
                    <a href="/barkod-skaner" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Barkod Skaner</a>
                    <a href="/card-reader" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Card Reader</a>
                    <a href="/data-terminal" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Data Terminal</a>
                    <a href="/barkod-printer" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Barkod Printer</a>
                    <a href="/cek-printer" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Çek Printer</a>
                    <a href="/barkod-oxuyucu" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Barkod Oxuyucu</a>
                    <a href="/barkod-terezi" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">Barkod Tərəzi</a>
                  </div>
                )}
              </div>
              <a href="/about" className="text-[#086170] font-bold">Proqram təminatı</a>
              <a href="/about" className="text-[#086170] font-bold">Ərizə formaları</a>
              <a href="/contact" className="text-[#086170] font-bold">Əlaqə</a>
            </div>
            <div> 
            <a href='/'> <img src={mpost} alt="Logo" className="h-8 mb-2" /></a>
            <a href="/contact">
  <button className="bg-[#086170] text-xs text-white px-1 py-2 rounded font-bold scaling-button">
    Ödənişsiz yükləyin
  </button>
</a>

<style jsx>{`
  @keyframes scale {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  .scaling-button {
    animation: scale 1.5s infinite ease-in-out;
  }
`}</style>


            </div>
          </div>
        </div>
      </nav>            
    </>
  ); 
};

export default Header;

