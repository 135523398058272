import React from "react";
import BannerImg from "./about-44.jpg";
import { FaUserTie, FaUsers, FaLightbulb } from "react-icons/fa";

const WhyUs = () => {
  return (
    <>
      <span id="about"></span>
      <div>
        <div className="min-h-[550px] flex justify-center items-center py-12 md:py-0">
          <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-[90%] md:w-[80%] mx-auto">
              <div className="flex flex-col justify-center gap-6 md:pt-0">
                <h1
                  data-aos="fade-up"
                  className="text-3xl md:text-4xl font-bold font-cursive"
                >
                  Niyə Biz?
                </h1>
                <p
                  data-aos="fade-up"
                  className="text-md md:text-base text-gray-500 tracking-wide leading-5"
                >
                  Yarandığımız 2021-ci ildən bəri 200 dən çox şirkət bizə güvənərək proqram və konsaltinq xidmətimizdən istifadə edir. Proqramlaşdırma və Konsaltinq sahəsində təcrübəli və peşəkar komandamız sizə ən yaxşı xidməti göstərmək üçün çalışır. 
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-5">
                    <div data-aos="fade-up" className="flex items-center gap-3">
                      <FaUserTie className="text-white text-2xl h-12 w-12 shadow-md p-3 rounded-full bg-[#086170]" />
                      <span className="text-md md:text-base">Müştərilərə fərdi yanaşma</span>
                    </div>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="300"
                      className="flex items-center gap-3"
                    >
                      <FaUsers className="text-white text-2xl h-12 w-12 shadow-md p-3 rounded-full bg-[#086170]" />
                      <span className="text-md md:text-base">Peşəkar və təcrübəli işçilər</span>
                    </div>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="500"
                      className="flex items-center gap-3"
                    >
                      <FaLightbulb className="text-white text-2xl h-12 w-12 shadow-md p-3 rounded-full bg-[#086170]" />
                      <span className="text-md md:text-base">İnnovativ və modern həllər</span>
                    </div>
                  </div>
                  <div
                    data-aos="slide-left"
                    className="border-l-4 border-primary/50 pl-6 space-y-2"
                  >
                    <h1 className="text-2xl font-semibold font-cursive">
                      Peşəkarlıq
                    </h1>
                    <p className="text-md md:text-base text-gray-500">
                    “İNTEKO” Məhdud Məsuliyyətli Cəmiyyəti yerli Azərbaycan şirkəti olaraq 2021-ci ilin fevral ayının 24-də fəaliyyətə başlamışdır.
                    </p>
                  </div>
                </div>
              </div>
              {/* Görsel bölümü */}
              <div data-aos="zoom-in" className="ml-auto mt-10 md:mt-0">
                <img
                  src={BannerImg}
                  alt="biryani img"
                  className="max-w-full w-full mx-auto"
                />
              </div>
              {/* Metin içeriği bölümü */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
