import './product.css'
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { barkodOxuyucu, barkodPrinter } from '../../../assets/index';

export const products = [
  {
    image: barkodOxuyucu,
    name: "Pul yeşikləri",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/pul-yesikleri"
  },
  {
    image: barkodPrinter,
    name: "All İn One",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/all-in-one"
  },
  {
    image: barkodOxuyucu,
    name: "Barkod Skaner",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/barkod-skaner"
  },

  {
    image: barkodPrinter,
    name: "Card Reader",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/card-reader"
  },
  {
    image: barkodOxuyucu,
    name: "Data Terminal",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/data-terminal"
  },

  {
    image: barkodPrinter,
    name: "Barkod Printer",
    discription: "Contrary to popular belief, ipsum is not simply random.",
    path: "/barkod-printer"
  }

];

const ProductShort = () => {

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <div className="container-xxl py-5">
       <h1 className="text-3xl font-extrabold text-center mb-5 text-[#086170]">Məhsullar</h1>
       <p className="text-center mb-10">Satışlarınızın idarə olunmasını bizə etibar edin</p>
        <div className="container w-[80%]">
          <div className='container'>
      <div className='content grid3'>
        {products.map((val, index) => {
          const { image, category, name, path } = val;
          return (
            <div  data-aos="fade-up" className='box shadow' key={index}>
              
              <div className='img'>
                <img src={image} alt={name} />
              </div>
              <div className='text'>
                <div className='category flex'>
                  <span style={{backgroundColor: 'white'}}></span>
                  <i className='fa fa-heart'></i>
                </div>
                <h4>{name}</h4>
                <p>
                  <i style={{color: '#086170'}} className='fa-solid fa-location-dot'></i> Bakı, Azərbaycan
                </p>
                <a href={path}><button className='details-button'>Ətraflı</button></a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
        </div>
      </div>
  );
};

export default ProductShort;
