import React, { useEffect } from "react";
import Heading from "../../../Components/home/HeadingServices";
import AboutUs from '../../../Components/home/About/About';
import Team from "../../../Components/home/Team/Team";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { muhasibatliq, proqramlasdirma } from "../../../assets";

export default function Proqramlasdirma() {

    useEffect(() => {
        AOS.init({ duration: 1500 });
      }, []);
  return (
    <>
     <div className="relative bg-gray-900 bg-opacity-80 text-white h-80 flex flex-col items-center justify-center">
      <img src={proqramlasdirma} alt="Background" className="absolute inset-0 w-full h-full object-cover opacity-40" />
      <div className="relative z-10 text-center px-4">
        <h1 className="text-5xl font-extrabold mt-2 tracking-tight">Proqramlaşdırma</h1>
        <div className='mt-5 flex items-center justify-center space-x-2'>
        <i class="fa-solid fa-house"></i>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">Əsas</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">Xidmətlər</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <span className="text-sm uppercase tracking-wide text-gray-300">Proqramlaşdırma</span>
        </div>
      </div>
    </div>
      <div className="flex flex-col items-center mt-12 mb-8">
      <div className="w-[80%]">
        <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-6 ">
          <div className="p-6 flex flex-col justify-center ml-10">
            <h1 className="text-2xl font-bold mb-4 text-[#086170]">Proqramlaşdırma</h1>
            <p className="mb-4">
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
            </p>
          </div>
        </div>
       
      </div>
    </div>
    </>
  );
}
