import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { carousel1, carousel2, carousel3, carousel4, carousel5 } from "../../../assets/index";

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0); 
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: carousel1, text: "Rəqəmlərimiz Sizi Uğura Aparır" },
    { img: carousel2, text: "Mpost satış proqramı ilə gəlirlərinizə nəzarət edin" },
    { img: carousel3, text: "Mühasibatlıq və HR xidmətləri üzrə optimallaşdırma" },
    { img: carousel4, text: "İnnovativ proqram həlləri" },
    { img: carousel5, text: "İstənilən növ e-kassaların inteqrasiyası" },
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500); // Show text after 0.5 seconds

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500); // Show button after 1.5 seconds

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 5000, // Delay before auto-slide
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      onSlideChange={(swiper) => {
        setShowText(false);
        setShowButton(false);
        setActiveIndex(swiper.activeIndex);
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className="slide-content relative">
            <img src={slide.img} alt={`Slide ${index + 1}`} className="w-full h-full object-contain" />
            {activeIndex === index && (
              <div className="absolute inset-0 flex flex-col justify-center items-center bg-opacity-50 bg-blue-900 text-white p-4 transition-opacity duration-1000">
                <h2 className={`w-[80%] text-center text-6xl font-bold mb-14 transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>{slide.text}</h2>
                <h2 className={`text-xl font-bold transition-all duration-1000 ${showText ? 'opacity-100' : 'opacity-0'}`}>{slide.text}</h2>
                <a href="/products"><button className={`btn btn-primary mt-4 p-2 bg-blue-600 px-8 rounded-xl py-2 transition-all duration-1000 transform ${showButton ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>Məhsullara keç</button></a>
              </div>
            )}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
