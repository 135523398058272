import React from "react";
import Heading from "../../Components/home/Heading";
import Product from '../../Components/Product/Product';

export default function Products() {
  return (
    <>
      <Heading heading="Məhsullar" title="Əsas" subtitle="Məhsullar" />
      <Product/>
    </>
  );
}
