import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about } from '../../../assets/index';

const About = () => {

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  
  return (
    <div className="flex flex-col items-center mt-12 mb-8">
    <div className="w-full sm:w-[90%] lg:w-[80%] px-4 sm:px-6 lg:px-0">
      <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-4 sm:p-6 bg-white">
        <div className="md:w-1/2 flex justify-center md:justify-start">
          <img className='max-h-80 md:max-h-96 mt-4 md:mt-10 w-full object-contain' src={about} alt='About'></img>
        </div>
        <div className="md:w-1/2 p-4 sm:p-6 flex flex-col justify-center mt-6 md:mt-0 md:ml-10">
          <h1 className="text-xl sm:text-2xl font-bold mb-4 text-[#086170]">Haqqımızda</h1>
          <h1 className="text-xl sm:text-2xl font-bold mb-4">“İNTEKO” haqqında məlumat (İnnovativ Texnologiya Kompaniyası)</h1>
          <p className="text-sm sm:text-base mb-4">
            “İNTEKO” Məhdud Məsuliyyətli Cəmiyyəti yerli Azərbaycan şirkəti olaraq 2021-ci ilin fevral ayının 24-də fəaliyyətə başlamışdır. Şirkətin fəaliyyət profili - sahibkarlıq fəaliyyəti ilə məşğul olan vergi ödəyicilərinə maliyyə və HR xidmətlərinin göstərilməsi, şəbəkələrin texniki avadanlıqlara inteqrasiyası, proqram təminatı üzrə idarə olunma sistemlərinin tətbiqi və ona nəzarətdir. Şirkət olaraq yerli və xarici mühəndislərimizin peşəkar təcrübəsini tətbiq edərək, idarəçilik mexanizmlərini tətbiq edirik ki, bu da gələcəkdə partnyorluq etdiyimiz şirkətlərin biznes riskini minimuma endirməyə xidmət edir.
          </p>
          <ul className="list-none grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2">
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              Konfidensiallıq
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              Operativlik
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              Dəqiqlik
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              Keyfiyyət
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              İndividuallıq
            </li>
            <li className="flex items-center">
              <i className="fa-solid fa-check mr-2 text-[#086170]"></i>
              Peşəkarlıq
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default About;
