import React, { useEffect, useState } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import { azflag, engflag, rusflag } from '../../assets/index';
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";

const languageOptions = [
  { value: "en", label: "English", flag: engflag },
  { value: "az", label: "Azərbaycan", flag: azflag },
  { value: "rus", label: "Russian", flag: rusflag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation },
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

const AboveHeader = () => {

  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };

  return (
    <>
      <header className="bg-[#086170] text-white">
        <div className="flex flex-wrap justify-between items-center p-2 w-[89%] mx-auto">
          <div className="flex space-x-4">
            <a href="https://www.instagram.com" className="text-white">
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a href="https://www.facebook.com" className="text-white">
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a href="https://www.linkedin.com" className="text-white">
              <i className="fa-brands fa-linkedin"></i>
            </a>
            <a href="https://www.tiktok.com" className="text-white">
              <i className="fa-brands fa-tiktok"></i>
            </a>
          </div>
          
          <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <span className="font-nunito text-lg">2021-ci ildən Sizinlə</span>
            <button
              onClick={toggleDropdown}
              className="flex items-center space-x-2 px-4 py-2 border rounded-full border-teal-500"
            >
              <span>
                <img
                  src={selectedLanguage.flag}
                  alt={`${selectedLanguage.label} Flag`}
                  className="h-6 w-5"
                />
              </span>
              <span>{selectedLanguage.label}</span>
            </button>

            {isOpen && (
              <div className="absolute right-0 top-10 mt-2 w-40 rounded-md shadow-lg bg-[#086170] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-teal-400"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default AboveHeader;
















