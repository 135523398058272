import React, { useEffect } from "react";
import Heading from "../../../Components/home/HeadingServices";
import AboutUs from '../../../Components/home/About/About';
import Team from "../../../Components/home/Team/Team";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { carousel5, eKassa } from "../../../assets";

export default function Ekassa() {
    useEffect(() => {
        AOS.init({ duration: 1500 });
    }, []);

    return (
        <>
             <div className="relative bg-gray-900 bg-opacity-80 text-white h-80 flex flex-col items-center justify-center">
      <img src={carousel5} alt="Background" className="absolute inset-0 w-full h-full object-cover opacity-40" />
      <div className="relative z-10 text-center px-4">
        <h1 className="text-5xl font-extrabold mt-2 tracking-tight">E-kassa</h1>
        <div className='mt-5 flex items-center justify-center space-x-2'>
        <i class="fa-solid fa-house"></i>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">Əsas</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">Xidmətlər</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <span className="text-sm uppercase tracking-wide text-gray-300">E-kassa</span>
        </div>
      </div>
    </div>
            <div className="flex flex-col items-center mt-12 mb-8">
                <div className="w-[80%]">
                    <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-6">
                        <div className="p-6 flex flex-col justify-center ml-10">
                            <h1 className="text-2xl font-bold mb-4 text-[#086170]">E-kassa</h1>
                            <p className="mb-4">
                                İnteko şirkəti Azərbaycan Respublikasının Nazirlər Kabinetinin 52 nömrəli, 2019-cu il 14 fevral tarixli qərarına əsasən yeni nəsil elektron kassalara tətbiqi və inteqrasiyası ilə bağlı irəli gələn bütün işləri peşəkar komanda ilə icra etməkdədir. Şirkətimiz rezident hüquqi şəxs kimi e-kassa qismində Dövlət Vergi Xidmətində qeydiyyatdan keçmiş Omnitech, NBA Technologies International, CASPOS, Az Smart, One Click, DataPay MMC operatorları ilə uğurla əməkdaşlıq edir və Respublika üzrə fəaliyyət göstərən bütün vergi ödəyicilərinə aşağıdakı xidmətlərin icrasından irəli gələn məsələlərin operativ həllərini təklif edir:
                                <ul className="list-disc ml-5 mt-2">
                                    <li>Kassaların alışının həyata keçirilməsi.</li>
                                    <li>Kassalara texniki xidmət.</li>
                                    <li>Kassaların təkrar uçota alınması.</li>
                                    <li>Kassaların uçotdan çıxarılması.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

