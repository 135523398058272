import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { avatar } from '../../../assets/index';

const teamMembers = [
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar },
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar },
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar},
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar },
  { name: "Əli Əliyev", title: "Lorem ipsum dolo sit amet", image: avatar },
];

const Team = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="py-12  bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-[#086170] tracking-wide uppercase">Komandamız</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Komandamız ilə Tanış Olun!
          </p>
        </div>
        <div class="flex lg:flex-row flex-col justify-between gap-8 pt-12 mx-auto">
        <div class="w-full lg:w-5/12 flex flex-col justify-center">
            <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-[#086170] dark:text-white pb-4">Komandamız</h1>
            <p class="font-normal text-base leading-6 text-gray-600 dark:text-white">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.In the first place we have granted to God, and by this our present charter confirmed for us and our heirs forever that the English Church shall be free, and shall have her rights entire, and her liberties inviolate; and we will that it be thus observed; which is apparent from</p>
        </div>
        <div class="w-full lg:w-8/12 lg:pt-8">
            <div class="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                    <img class="md:block hidden" src={avatar} alt="Alexa featured Image" />
                    <img class="md:hidden block" src={avatar} alt="Alexa featured Image" />
                    <p class="font-medium text-xl leading-5 text-gray-800 dark:text-white mt-4">Lorem</p>
                </div>
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                    <img class="md:block hidden" src={avatar} alt="Olivia featured Image" />
                    <img class="md:hidden block" src={avatar} alt="Olivia featured Image" />
                    <p class="font-medium text-xl leading-5 text-gray-800 dark:text-white mt-4">Lorem</p>
                </div>
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                    <img class="md:block hidden" src={avatar} alt="Liam featued Image" />
                    <img class="md:hidden block" src={avatar} alt="Liam featued Image" />
                    <p class="font-medium text-xl leading-5 text-gray-800 dark:text-white mt-4">Lorem</p>
                </div>
                <div class="p-4 pb-6 flex justify-center flex-col items-center">
                    <img class="md:block hidden" src={avatar} alt="Elijah featured image" />
                    <img class="md:hidden block" src={avatar} alt="Elijah featured image" />
                    <p class="font-medium text-xl leading-5 text-gray-800 dark:text-white mt-4">Lorem</p>
                </div>
            </div>
        </div>
    </div>
      </div>
    </div>
  );
}

export default Team;
