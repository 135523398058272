import React, { useEffect } from "react";
import Heading from "../../../Components/home/HeadingServices";
import AboutUs from '../../../Components/home/About/About';
import Team from "../../../Components/home/Team/Team";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { muhasibatliq, photo3, vergi } from "../../../assets";

export default function VergiUcotu() {

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
      <div className="relative bg-gray-900 bg-opacity-80 text-white h-80 flex flex-col items-center justify-center">
      <img src={vergi} alt="Background" className="absolute inset-0 w-full h-full object-cover opacity-40" />
      <div className="relative z-10 text-center px-4">
        <h1 className="text-5xl font-extrabold mt-2 tracking-tight">Vergi uçotu</h1>
        <div className='mt-5 flex items-center justify-center space-x-2'>
        <i class="fa-solid fa-house"></i>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">Əsas</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">Xidmətlər</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <span className="text-sm uppercase tracking-wide text-gray-300">Vergi uçotu</span>
        </div>
      </div>
    </div>
      <div className="flex flex-col items-center mt-12 mb-8">
        <div className="w-[80%]">
          <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-6">
            <div className="p-6 flex flex-col justify-center ml-10">
              <h1 className="text-2xl font-bold mb-4 text-[#086170]">Vergi Uçotu</h1>
              <p className="mb-4">
                Bütün vergi ödəyiciləri Mühasibat uçotunun aparılması üzrə outsorsinq xidmətlərimizdən istifadə etməklə müştərilər ixtisaslı mühasiblərdən təşkil olunmuş komandamızdan, daha səmərəli və dəqiq maliyyə xidmətlərindən, o cümlədən xərclərin və risklərin azaldılması imkanından yararlana bilər.
              </p>
              <p className="mb-4">
                Bizim mühasibatlıq xidmətlərimiz aşağıdakı fəaliyyətləri əhatə edir:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>Qanunla müəyyən edilmiş standartlara və Maliyyə hesabatlarının Beynəlxalq Standartlarına uyğun olaraq gündəlik mühasibatlıq xidmətlərinin təmin edilməsi</li>
                <li>Hesabatların hazırlanması və dövlət orqanlarına təqdim edilməsi</li>
                <li>Mühasibatlığın yaradılması və optimallaşdırılması</li>
                <li>Mühasibatlığın və vergiyə uyğunluğun təftişi (auditi)</li>
                <li>Əsas vəsaitlərin inventarizasiyası</li>
                <li>Əsas mühasibat kitabı</li>
                <li>Jurnalda qeydiyyat və hesablamaların aparılması</li>
                <li>Razılaşdırılmış hesablar planında aylıq sınaq balanslarının qurulması</li>
                <li>Depozit hesab və bank hesabı üzrə əməliyyatların aparılması</li>
                <li>İllik hesabatvermə</li>
                <li>Bank hesabının yaradılması və üzləşdirilməsi</li>
                <li>Amortizasiya cədvəllərinin hazırlanması</li>
                <li>Əmək haqqının hesablanılması xidmətləri</li>
              </ul>
              <p className="mb-4">
                Ödənişlərin həyata keçirilməsi, hesab-fakturaların hazırlanması, gəlirlərin uçotunun aparılması, bank əməliyyatlarının icra edilməsi ilə yanaşı biz həm də müştərilərimizin ehtiyaclarını qarşılamaq üçün onlara dövri olaraq idarəetmə uçotu ilə bağlı məlumat və hesabat təqdim edirik.
              </p>
              <p className="mb-4">
                Bu cür hesabatların forması və həmin hesabatlara daxil ediləcək məlumatlar dəyişkən ola bilər və hər bir müştərinin biznesinin hesabatvermə ehtiyaclarına uyğun olaraq hazırlana bilər.
              </p>
              <p className="mb-4">
                Siz mühasibat uçotu və hesabatvermə üzrə peşəkar mütəxəssisləri işə götürmədən belə asanlıqla həmin mütəxəssislərə çıxış imkanı əldə edəcəksiniz. Əgər sizin biznesiniz varsa, lakin daxili mühasibiniz kifayət qədər ixtisaslı deyilsə və ya iş axını ilə ayaqlaşa bilmirsə, biz sərfəli qiymətlərlə ən səmərəli mühasibatlıq həllərini təmin edə bilərik.
              </p>
              <p className="mb-4">
                Bizim mühasibatlıq xidmətlərimiz sizə xərclərinizi azaltmağa və işlərinizi səmərəli idarə etməyə kömək edəcək.
              </p>
              <p className="mb-4">
                Biznes sahibi kimi siz öz biznesinizi idarə etmək üçün gündəlik fəaliyyətlərlə məşğul olmalısınız. Bununla belə, biz sizin biznesinizin uzunömürlülüyünü dəstəkləmək üçün ən uğurlu yolun müəyyən edilməsi məqsədilə geriyə qayıdaraq böyük mənzərəyə obyektiv baxa bilərik.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
