import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import AboveHeader from "./Components/AboveHeader/AboveHeader";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
import Contact from "./Pages/Contact/Contact";
import Products from "./Pages/Products/Products";
import Partners from "./Pages/Partners/Partners";

import PulYesikleri from "./Pages/AllProducts/PulYesikleri/PulYesikleri";
import AllInOne from "./Pages/AllProducts/AllInOne/AllInOne";
import BarkodSkaner from "./Pages/AllProducts/BarkodSkaner/BarkodSkaner";
import CardReader from "./Pages/AllProducts/CardReader/CardReader";
import DataTerminal from "./Pages/AllProducts/DataTerminal/DataTerminal";
import BarkodPrinter from "./Pages/AllProducts/BarkodPrinter/BarkodPrinter";
import CekPrinter from "./Pages/AllProducts/CekPrinter/CekPrinter";
import BarkodOxuyucu from "./Pages/AllProducts/BarkodOxuyucu/BarkodOxuyucu";
import BarkodTerezi from "./Pages/AllProducts/BarkodTerezi/BarkodTerezi";

import DetailedPage from "./Pages/DetailedPage/DetailedPage.js";
import Muhasibatliq from "./Pages/AllServices/Muhasibatliq/Muhasibatliq.js";
import Maliyye from "./Pages/AllServices/Maliyye/Maliyye.js";
import VergiUcotu from "./Pages/AllServices/VergiUcotu/VergiUcotu.js";
import InsanResurslari from "./Pages/AllServices/InsanResurlari/InsanResurslari.js";
import Ekassa from "./Pages/AllServices/Ekassa/Ekassa.js";
import Proqramlasdirma from "./Pages/AllServices/Proqramlasdirma/Proqramlasdirma.js";
import SebekeIdareetmesi from "./Pages/AllServices/SebekeIdareetmesi/SebekeIdareetmesi.js";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const Layout = () => {
  return ( 
    <div>
      <AboveHeader />
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/products" element={<Products />}></Route>
        <Route path="/partners" element={<Partners />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/detailed-page" element={<DetailedPage />} />
        <Route path="/pul-yesikleri" element={<PulYesikleri />} />
        <Route path="/all-in-one" element={<AllInOne />} />
        <Route path="/barkod-skaner" element={<BarkodSkaner />} />
        <Route path="/card-reader" element={<CardReader />} />
        <Route path="/data-terminal" element={<DataTerminal />} />
        <Route path="/barkod-printer" element={<BarkodPrinter />} />
        <Route path="/cek-printer" element={<CekPrinter />} />
        <Route path="/barkod-oxuyucu" element={<BarkodOxuyucu />} />
        <Route path="/barkod-terezi" element={<BarkodTerezi />} />
        <Route path="/muhasibatliq" element={<Muhasibatliq />} />
        <Route path="/maliyye" element={<Maliyye />} />
        <Route path="/vergi-ucotu" element={<VergiUcotu />} />
        <Route path="/insan-resurslari" element={<InsanResurslari />} />
        <Route path="/e-kassa" element={<Ekassa />} />
        <Route path="/proqramlasdirma" element={<Proqramlasdirma />} />
        <Route path="/sebeke-idareetmesi" element={<SebekeIdareetmesi />} />
      </Route>
    </Route>
  )
);

function App() {

  return (
    <div className="">
      <div className="font-bodyFont">
      <div className="max-w-[1740px] mx-auto">
    <FloatingWhatsApp phoneNumber='+994502062366' accountName="INTEKO" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' />
      <RouterProvider router={router} />
      </div>
    </div>
    </div>
  );
}

export default App;
