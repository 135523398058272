import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";

export default function About() {
  return (
    <>
      <Heading heading="Haqqımızda" title="Əsas" subtitle="Haqqımızda" />
      <AboutUs/>
      <Team/>
    </>
  );
}
