

import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { map } from '../../assets/index'
import { CiMail } from "react-icons/ci";
import Heading from "../../Components/home/Heading";


const Contact = () => {

  return (
    <>
    <Heading heading="Əlaqə" title="Əsas" subtitle="Əlaqə" />
     <div className="p-8 min-h-screen">
     <div className="max-w-5xl mx-auto bg-white p-4 md:p-6 lg:p-8 rounded-md">
  <div className="flex flex-col md:flex-row justify-between items-start md:items-start md:space-x-6">
    <div className="w-full md:w-1/3 mb-6 md:mb-0">
      <div className="flex items-center mb-4 md:mb-6">
        <div className="border-l-[4px] border-[#086170] h-6 mr-2"></div>
        <h2 className="text-xl md:text-2xl font-semibold">Əlaqə vasitələri</h2>
      </div>
      <div className="space-y-4 md:space-y-6">
        <div className="bg-[#086170] text-white p-4 md:p-6 rounded-md flex items-center space-x-4">
          <FaWhatsapp size={20} className="md:size-24" />
          <div>
            <h3 className="text-md md:text-lg font-semibold mb-1 md:mb-2">Bizə zəng edin</h3>
            <p className="text-sm md:text-base mb-1">+994 55 206 23 66</p>
            <p className="text-sm md:text-base">+994 55 206 23 77</p>
          </div>
        </div>
        <div className="bg-[#086170] text-white p-4 md:p-6 rounded-md flex items-center space-x-4">
          <CiMail size={20} className="md:size-24" />
          <div>
            <h3 className="text-md md:text-lg font-semibold mb-1 md:mb-2">Bizə yazın</h3>
            <p className="text-sm md:text-base mb-1">info@inteko.az</p>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-2/3">
      <div className="flex items-center mb-4 md:mb-6">
        <div className="border-l-[4px] border-[#086170] h-6 mr-2"></div>
        <h2 className="text-xl md:text-2xl font-semibold">Bizə müraciət edin</h2>
      </div>
      <form className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <input
          type="text"
          placeholder="Adınız"
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <input
          type="email"
          placeholder="Email"
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <input
          type="text"
          placeholder="Telefon"
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <input
          type="text"
          placeholder="Mesaj"
          className="p-2 border border-gray-300 rounded-md w-full text-sm md:text-base"
        />
        <textarea
          placeholder="İstək"
          className="p-2 border border-gray-300 rounded-md col-span-1 md:col-span-2 h-32 w-full text-sm md:text-base resize-none"
        ></textarea>
        <button
          type="submit"
          className="col-span-1 md:col-span-2 bg-[#086170] text-white p-2 md:p-3 rounded-md w-full text-sm md:text-base font-semibold"
        >
          Göndər
        </button>
      </form>
    </div>
  </div>
</div>



      <div  style={{ backgroundImage: `url(${map})` }}  className="h-full w-full bg-teal-400 flex items-center justify-center mt-24">
        <div className="text-4xl font-bold ml-4">Biz burdayıq</div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18594.002601544133!2d49.87089497071087!3d40.386305500921694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d3c46b1a725%3A0x369ec29d5d8505e4!2zw4dheWtvdnNraSwgQmFrxLE!5e0!3m2!1saz!2saz!4v1722322418337!5m2!1saz!2saz" width='1400' height='150'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      
      </div>
    </div>
    </>

  );
};

export default Contact;
