import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './partners.css'

export const priceShort = [
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners14.png",
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners15.png",
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners16.jpg",
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners17.jpg",
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners18.jpg",
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners19.jpg",
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners20.jpg",
  },
  {
    plan: "Biznes",
    price: "79",
    ptext: "2 user, per month",
    image: "../assets/img/partners/partners20.jpg",
  },
]

const Partners = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div  style={{ 
      backgroundImage: `linear-gradient(#F7F8FB, rgba(0, 0, 0, 0.1)), url('./map.png')`, 
      height: '70vh',
      maxHeight: '70vh'
    }} className="container-xxl py-5 h-screen flex flex-col justify-center">
     <div className="w-[90%] mx-auto">
     <div className="container">
     <h1 className="text-4xl font-extrabold text-center mb-5 text-[#086170]">Müştərilərimiz</h1>
     <p className="text-center mb-10 text-xl">Biznesinizi bizimlə inkişaf etdirin</p>
        <div data-aos="zoom-in">
          <Slider {...settings}>
            {priceShort.map((data, index) => (
              <div className="flex justify-center items-center">
                <div className="h-40 w-40 mx-4" key={index}>
                  <img
                    src={data.image}
                    alt={data.plan}
                    className="testimonial-img h-40 w-40 object-contain"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
     </div>
    </div>
  );
};

export default Partners;
