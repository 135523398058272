import React from 'react'

const Awards = () => {
  return (
    <div className="bg-[#0C223E] text-white text-center py-20">
         <div className='mb-14'>
         <h2 className="text-red-600 text-2xl text-center">Nəticələrimiz</h2>
      <h1 className="text-3xl font-semibold my-4 text-center">300+ müştəri məmnuniyyəti</h1>
      </div>
      <div className="flex justify-around">
        <div className="text-center">
          <div className="bg-[#143D62] p-4 rounded-full inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" viewBox="0 0 20 20" fill="white">
              <path d="M10 3l1.67 5.015h5.261L13.34 12.325l1.665 5.01L10 14.015 5.995 17.335l1.665-5.01L3.075 8.015h5.26L10 3z" />
            </svg>
          </div>
          <h2 className="text-4xl font-bold mt-2">150+</h2>
          <p className="text-gray-400">Müştərilər</p>
        </div>
        <div className="text-center">
          <div className="bg-[#143D62] p-4 rounded-full inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" viewBox="0 0 20 20" fill="white">
              <path d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm4 13a3 3 0 110-6 3 3 0 010 6zm1-9H7V4h4v2z" />
            </svg>
          </div>
          <h2 className="text-4xl font-bold mt-2">6</h2>
          <p className="text-gray-400">Tərəfdaşlar</p>
        </div>
        <div className="text-center">
          <div className="bg-[#143D62] p-4 rounded-full inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" viewBox="0 0 20 20" fill="white">
              <path d="M11 3a1 1 0 10-2 0v2H7V3a1 1 0 10-2 0v2H3.5A1.5 1.5 0 002 6.5v11A1.5 1.5 0 003.5 19h13a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0016.5 5H15V3a1 1 0 10-2 0v2h-2V3zM4 8h12v2H4V8zm12 4H4v6h12v-6z" />
            </svg>
          </div>
          <h2 className="text-4xl font-bold mt-2">214</h2>
          <p className="text-gray-400">İstifadəçilər</p>
        </div>
        <div className="text-center">
          <div className="bg-[#143D62] p-4 rounded-full inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" viewBox="0 0 20 20" fill="white">
              <path d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.656l-6.828-6.828a4 4 0 010-5.656z" />
            </svg>
          </div>
          <h2 className="text-4xl font-bold mt-2">5</h2>
          <p className="text-gray-400">Uğurlu layihə</p>
        </div>
      </div>
    </div>
  )
}

export default Awards